import * as React from 'react';
import { withLayout } from '../components/Layout';
import { FixedMasthead } from '../components/Masthead/FixedMasthead.component';
import { Constrain, LinkArrow, Link } from '../components/ui';
import styled from '../styled-components';
import waveNotFoundImg from '../images/404-wave-not-found.svg';

const NotFoundPageContent = styled.div`
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing.xxl};
    min-height: 50vh;

    a {
        justify-content: center;
    }

    img {
        margin-bottom: 3rem;
    }

    h1 {
        font-family: ${({ theme }) => theme.fontNew.secondary.type};
        font-size: ${({ theme }) => theme.fontNew.secondary.size.level2.size};
        line-height: ${({ theme }) =>
            theme.fontNew.secondary.size.level2.lineHeight};
        font-weight: normal;
    }

    p {
        font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
    }
`;
const NotFoundPage = () => (
    <>
        <FixedMasthead />
        <NotFoundPageContent>
            <Constrain type="narrow">
                <div>
                    <img src={waveNotFoundImg} alt="404 wave not found" />
                    <h1>404 - Wave not found!</h1>
                    <p>Sorry, we can't find the page you were looking for.</p>
                    <p>
                        <LinkArrow
                            to="/"
                            color="deepBlue"
                            arrowColor={'electricBlue'}
                        >
                            Take me to the homepage
                        </LinkArrow>
                    </p>
                </div>
            </Constrain>
        </NotFoundPageContent>
    </>
);

export default withLayout(NotFoundPage);
